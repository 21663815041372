#floating-footer.show {
  opacity: 1;
  transition: opacity 1000ms 600ms;
}

#floating-footer {
  width: 100vw;
  height: 64px;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3e523c;
  color: #ffffff;
  z-index: 999;
  opacity: 0;

  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);

  .Index-page-content {
    padding: 0;
  }

  .sqs-block {
    padding: 0;

    @include mq() {
      padding: 0!important;
    }
  }

  .floating-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &__text {
      margin-right: 20px;

      @include mq() {
        margin-right: 14px;
        transform: translateX(-15px);
      }

      @include mq($breakpoint: sm) {
        margin-right: 5px;
        transform: translateX(0);
      }
    }

    &__limited {
      margin: 0;
      margin-bottom: -2px;
      @include fontSizePxToRem(16);
      font-family: "YakuHanJP", Midashi Go MB31, sans-serif;
      font-style: normal;
      font-weight: 600;
      text-align: right;

      @include mq() {
        @include fontSizePxToRem(14);
      }
    }

    &__date {
      margin: 0;
      @include fontSizePxToRem(11);
      font-family: "YakuHanJP", Midashi Go MB31, sans-serif;
      font-style: normal;
      font-weight: 600;
      text-align: right;

      @include mq() {
        @include fontSizePxToRem(10);
      }
    }
  }

  .button {
    width: 150.5px;
    display: block;
    padding: 12px 0;
    background-color: #c1c1c1;
    border-radius: 25px;

    &__inner {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    &__icon {
      width: 21px;
      height: 21px;
    }

    &__text {
      margin-left: 5%;
    }

    &__text--reserve {
      @include fontSizePxToRem(19);
      color: #3e523c;
      font-family: "YakuHanJP", Midashi Go MB31, sans-serif;
      font-style: normal;
      font-weight: 600;
    }
  }
}