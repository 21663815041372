@include mq() {
  .Index-page-image {
    background-image: url("https://common.lp.koyamachuya.com/hand-wound-watch.lp.koyamachuya.com/images/top-sp.jpg");
    background-repeat: no-repeat;
    background-size: 100vw 147.2vw;
    background-position: top center;
    background-color: #3e523c;
  }
}

@include mq() {
  .Index-page-image img {
    display: none;
  }
}

@include mq() {
  .tweak-index-page-fullscreen-first-page-only {
    .Index-page--has-image:first-child {
      min-height: 147.2vw;
    }
  }
}

@include mq() {
  .tweak-overlay-parallax-enabled {
    .Parallax-item {
      background-color: transparent;
    }
  }
}

#main {
  position: relative;
  .Index-page-content {
    position: absolute;
    top: 3vw;
    left: 0;
    padding: 0;

    @include mq() {
      top: 1.3vw;
    }
  }

  .atf {
    display: flex;
    justify-content: center;

    &__sub-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    &__mutta-serika-text {
      margin: 0;
      margin-left: 1%;
      @include fontSizePxToRem(19);
      font-family: "YakuHanJP", 'Times New Roman', Times, serif;
      font-style: italic;
      font-weight: 300;
      color: #6b8e68;

      @include mq() {
        @include fontSizePxToRem(17);
      }
    }

    &__hand-winding-text {
      margin: 0;
      margin-right: 1%;
      @include fontSizePxToRem(23);
      font-family: "YakuHanJP", 'Times New Roman', Times, serif;
      font-style: normal;
      font-weight: bold;
      text-transform: uppercase;
      color: #6b8e68;

      @include mq() {
        @include fontSizePxToRem(21);
      }
    }

    &__title-text {
      margin-top: .3%;
      @include fontSizePxToRem(31);
      font-family: "YakuHanJP", a-otf-midashi-mi-ma31-pr6n, serif;
      font-style: normal;
      font-weight: 600;
      color: #ffffff;

      @include mq() {
        @include fontSizePxToRem(25);
      }

      @include mq($breakpoint: sm) {
        @include fontSizePxToRem(21);
      }
    }
  }

  .Index-page-scroll-indicator {
    display: none;
  }
}