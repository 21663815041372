#header {
  display: none;
}

body {
  font-family: YakuHanJP, Gothic Medium BBB, sans-serif;
}

h1, h2, h3 {
  font-family: YakuHanJP, Midashi Go MB31, sans-serif;
}

h3 {
  text-transform: inherit;
}

blockquote {
  padding: 0;
  font-size: 0.875em;
  font-style: normal;
}

button {
  opacity: 1;
  transition: opacity 500ms
}

button:hover, button:active, button:focus {
  opacity: 0.8;
}

@media screen and (max-width: 640px) {
  .Footer .sqs-gallery-design-grid-slide {
    width: 33.3333% !important;
  }
}