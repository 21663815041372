#point {
  position: relative;

  &::before {
    content: '';
    width: 1.62%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("https://common.lp.koyamachuya.com/hand-wound-watch.lp.koyamachuya.com/images/point-background.png");
    background-size: contain;
    background-repeat: repeat-y;
    z-index: 5;

    @include mq() {
      width: 2.83%;
    }
  }

  .Index-page-content {
    position: relative;
    background-color: #eaebea;

    @include mq() {
      padding-right: 0;
      padding-left: 0;
      padding-bottom: 0;
    }

    h2, p {
      color: #000000;
    }
  }
}