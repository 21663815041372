#header {
  display: none;
}

body {
  font-family: YakuHanJP, Gothic Medium BBB, sans-serif;
}

h1, h2, h3 {
  font-family: YakuHanJP, Midashi Go MB31, sans-serif;
}

h3 {
  text-transform: inherit;
}

blockquote {
  padding: 0;
  font-size: 0.875em;
  font-style: normal;
}

button {
  opacity: 1;
  transition: opacity 500ms;
}

button:hover, button:active, button:focus {
  opacity: 0.8;
}

@media screen and (max-width: 640px) {
  .Footer .sqs-gallery-design-grid-slide {
    width: 33.3333% !important;
  }
}

@media screen and (max-width: 735px) {
  body {
    padding-bottom: 64.5px;
  }
}

@media screen and (max-width: 735px) {
  .Index-page-image {
    background-image: url("https://common.lp.koyamachuya.com/hand-wound-watch.lp.koyamachuya.com/images/top-sp.jpg");
    background-repeat: no-repeat;
    background-size: 100vw 147.2vw;
    background-position: top center;
    background-color: #3e523c;
  }
}

@media screen and (max-width: 735px) {
  .Index-page-image img {
    display: none;
  }
}

@media screen and (max-width: 735px) {
  .tweak-index-page-fullscreen-first-page-only .Index-page--has-image:first-child {
    min-height: 147.2vw;
  }
}

@media screen and (max-width: 735px) {
  .tweak-overlay-parallax-enabled .Parallax-item {
    background-color: transparent;
  }
}

#main {
  position: relative;
}

#main .Index-page-content {
  position: absolute;
  top: 3vw;
  left: 0;
  padding: 0;
}

@media screen and (max-width: 735px) {
  #main .Index-page-content {
    top: 1.3vw;
  }
}

#main .atf {
  display: flex;
  justify-content: center;
}

#main .atf__sub-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

#main .atf__mutta-serika-text {
  margin: 0;
  margin-left: 1%;
  font-size: 19px;
  font-size: 1.1875rem;
  font-family: "YakuHanJP", 'Times New Roman', Times, serif;
  font-style: italic;
  font-weight: 300;
  color: #6b8e68;
}

@media screen and (max-width: 735px) {
  #main .atf__mutta-serika-text {
    font-size: 17px;
    font-size: 1.0625rem;
  }
}

#main .atf__hand-winding-text {
  margin: 0;
  margin-right: 1%;
  font-size: 23px;
  font-size: 1.4375rem;
  font-family: "YakuHanJP", 'Times New Roman', Times, serif;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  color: #6b8e68;
}

@media screen and (max-width: 735px) {
  #main .atf__hand-winding-text {
    font-size: 21px;
    font-size: 1.3125rem;
  }
}

#main .atf__title-text {
  margin-top: .3%;
  font-size: 31px;
  font-size: 1.9375rem;
  font-family: "YakuHanJP", a-otf-midashi-mi-ma31-pr6n, serif;
  font-style: normal;
  font-weight: 600;
  color: #ffffff;
}

@media screen and (max-width: 735px) {
  #main .atf__title-text {
    font-size: 25px;
    font-size: 1.5625rem;
  }
}

@media screen and (max-width: 374px) {
  #main .atf__title-text {
    font-size: 21px;
    font-size: 1.3125rem;
  }
}

#main .Index-page-scroll-indicator {
  display: none;
}

#benly {
  background-color: #3e523c;
}

#benly .Index-page-content {
  padding-top: 0;
  padding-bottom: 0;
}

#lead .Index-page-content {
  background-color: #3e523c;
}

#lead .Index-page-content h2, #lead .Index-page-content p {
  color: #fcfbf2;
}

#point {
  position: relative;
}

#point::before {
  content: '';
  width: 1.62%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("https://common.lp.koyamachuya.com/hand-wound-watch.lp.koyamachuya.com/images/point-background.png");
  background-size: contain;
  background-repeat: repeat-y;
  z-index: 5;
}

@media screen and (max-width: 735px) {
  #point::before {
    width: 2.83%;
  }
}

#point .Index-page-content {
  position: relative;
  background-color: #eaebea;
}

@media screen and (max-width: 735px) {
  #point .Index-page-content {
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 0;
  }
}

#point .Index-page-content h2, #point .Index-page-content p {
  color: #000000;
}

#buy .Index-page-content {
  background-color: #1b2d1b;
}

#buy .Index-page-content h2, #buy .Index-page-content h3, #buy .Index-page-content p, #buy .Index-page-content a {
  color: #fcfbf2;
}

#buy .purchase__date {
  margin-bottom: 0;
  font-size: 18px;
  font-size: 1.125rem;
  text-align: center;
  line-height: 1.5;
  font-family: YakuHanJP, Midashi Go MB31, sans-serif;
}

@media screen and (max-width: 735px) {
  #buy .purchase__date {
    margin-bottom: 2%;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1;
  }
}

@media screen and (max-width: 735px) {
  #buy .purchase__price-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

#buy .purchase__price {
  margin: 0;
  font-size: 28px;
  font-size: 1.75rem;
  text-align: center;
  line-height: 1.5;
  font-family: YakuHanJP, Midashi Go MB31, sans-serif;
}

@media screen and (max-width: 735px) {
  #buy .purchase__price {
    margin-right: 3%;
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 1;
  }
}

#buy .purchase__tax {
  margin-top: 0;
  font-size: 14px;
  font-size: 0.875rem;
  text-align: center;
}

@media screen and (max-width: 735px) {
  #buy .purchase__tax {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 1;
  }
}

#buy .purchase__form {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 735px) {
  #buy .purchase__form {
    flex-direction: column;
  }
}

#buy .purchase__form button {
  padding: 0;
  background-color: transparent;
  border: none;
}

#buy .purchase__form-mutta, #buy .purchase__form-serika {
  padding-right: 3%;
  padding-left: 3%;
}

@media screen and (max-width: 735px) {
  #buy .purchase__form-mutta, #buy .purchase__form-serika {
    padding-right: 8%;
    padding-left: 8%;
  }
}

@media screen and (max-width: 735px) {
  #buy .purchase__form-mutta {
    margin-bottom: 3%;
  }
}

#secretstory .Index-page-content {
  background-color: #f2f1e2;
}

#secretstory .Index-page-content a {
  color: #f2f1e2;
}

#details .Index-page-content {
  background-color: #e6e6e6;
}

#detailsalohapk .Index-page-content {
  padding-top: 0;
  background-color: #e6e6e6;
}

#floating-footer.show {
  opacity: 1;
  transition: opacity 1000ms 600ms;
}

#floating-footer {
  width: 100vw;
  height: 64px;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3e523c;
  color: #ffffff;
  z-index: 999;
  opacity: 0;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

#floating-footer .Index-page-content {
  padding: 0;
}

#floating-footer .sqs-block {
  padding: 0;
}

@media screen and (max-width: 735px) {
  #floating-footer .sqs-block {
    padding: 0 !important;
  }
}

#floating-footer .floating-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#floating-footer .floating-footer__text {
  margin-right: 20px;
}

@media screen and (max-width: 735px) {
  #floating-footer .floating-footer__text {
    margin-right: 14px;
    transform: translateX(-15px);
  }
}

@media screen and (max-width: 374px) {
  #floating-footer .floating-footer__text {
    margin-right: 5px;
    transform: translateX(0);
  }
}

#floating-footer .floating-footer__limited {
  margin: 0;
  margin-bottom: -2px;
  font-size: 16px;
  font-size: 1rem;
  font-family: "YakuHanJP", Midashi Go MB31, sans-serif;
  font-style: normal;
  font-weight: 600;
  text-align: right;
}

@media screen and (max-width: 735px) {
  #floating-footer .floating-footer__limited {
    font-size: 14px;
    font-size: 0.875rem;
  }
}

#floating-footer .floating-footer__date {
  margin: 0;
  font-size: 11px;
  font-size: 0.6875rem;
  font-family: "YakuHanJP", Midashi Go MB31, sans-serif;
  font-style: normal;
  font-weight: 600;
  text-align: right;
}

@media screen and (max-width: 735px) {
  #floating-footer .floating-footer__date {
    font-size: 10px;
    font-size: 0.625rem;
  }
}

#floating-footer .button {
  width: 150.5px;
  display: block;
  padding: 12px 0;
  background-color: #c1c1c1;
  border-radius: 25px;
}

#floating-footer .button__inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#floating-footer .button__icon {
  width: 21px;
  height: 21px;
}

#floating-footer .button__text {
  margin-left: 5%;
}

#floating-footer .button__text--reserve {
  font-size: 19px;
  font-size: 1.1875rem;
  color: #3e523c;
  font-family: "YakuHanJP", Midashi Go MB31, sans-serif;
  font-style: normal;
  font-weight: 600;
}
