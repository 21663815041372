#buy {
  .Index-page-content  {
    background-color: #1b2d1b;

    h2, h3, p, a {
      color: #fcfbf2;
    }
  }

  .purchase {
    &__date {
      margin-bottom: 0;
      @include fontSizePxToRem(18);
      text-align:center;
      line-height: 1.5;
      font-family: YakuHanJP, Midashi Go MB31, sans-serif;

      @include mq() {
        margin-bottom: 2%;
        @include fontSizePxToRem(14);
        line-height: 1;
      }
    }

    &__price-wrapper {
      @include mq() {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }

    &__price {
      margin: 0;
      @include fontSizePxToRem(28);
      text-align:center;
      line-height: 1.5;
      font-family: YakuHanJP, Midashi Go MB31, sans-serif;

      @include mq() {
        margin-right: 3%;
        @include fontSizePxToRem(20);
        line-height: 1;
      }
    }

    &__tax {
      margin-top: 0;
      @include fontSizePxToRem(14);
      text-align:center;

      @include mq() {
        @include fontSizePxToRem(12);
        line-height: 1;
      }
    }

    &__form {
      display: flex;
      flex-direction: row;

      @include mq() {
        flex-direction: column;
      }

      button {
        padding: 0;
        background-color: transparent;
        border: none;
      }
    }

    &__form-mutta,
    &__form-serika {
      padding-right: 3%;
      padding-left: 3%;

      @include mq() {
        padding-right: 8%;
        padding-left: 8%;
      }
    }

    &__form-mutta {
      @include mq() {
        margin-bottom: 3%;
      }
    }
  }
}